import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import { Button, Input } from 'components';
import { REGEXP } from 'consts';
import InfoFormContainer from './InfoFormContainer';

interface BuyerInfoFormProps {
  listingId: string;
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: { [key: string]: any; }) => void;
}

const BuyerInfoForm: React.FC<BuyerInfoFormProps> = ({ defaultValues, onSubmit }) => {
  const [showCobuyerField, setShowCobuyerField] = useState(false);
  const [showCompanyField, setShowCompanyField] = useState(false);
  const { register, errors, control, handleSubmit } = useForm<any>({
    defaultValues: {
      ...defaultValues,
      Buyer_name: defaultValues?.name,
      Buyer_email: defaultValues?.email,
      Buyer_phone: defaultValues?.phone
    },
    mode: 'onSubmit'
  });

  return (
    <InfoFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="info-form__title">Your Information</p>
        <div className="info-form__input-group">
          <Input
            className="info-form__input"
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            name="Buyer_name"
            defaultValue=""
            error={errors.Buyer_name}
            label="Your Full Name*"
            placeholder="Enter Name"
            data-cy="buyer_name"
          />
          <Input
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            name="Buyer_email"
            defaultValue=""
            error={errors.Buyer_email}
            className="info-form__input"
            label="Email Address*"
            placeholder="Enter Email"
            data-cy="buyer_email"
          />
        </div>

        <div className="info-form__input-group">
          <Controller
            control={control}
            name="Buyer_phone"
            rules={{
              required: 'Required',
              validate: value => !value.includes('_') || 'Required'
            }}
            defaultValue=""
            render={controllerProps => (
              <Input
                className="info-form__input"
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.Buyer_phone}
                label="Phone Number*"
                placeholder="Enter Phone Number"
                {...controllerProps}
                data-cy="buyer_phone"
              />
            )}
          />
        </div>

        <div className="info-form__btn-group">
          <Button
            simple
            className="info-form__btn-add"
            onClick={e => {
              e.preventDefault();
              setShowCobuyerField(v => !v);
            }}
            data-cy="add_cobuyer">
            {showCobuyerField ? '-' : '+'} Add a co-buyer
          </Button>
        </div>

        {showCobuyerField && (
          <div className="info-form__input-group">
            <Input
              className="info-form__input"
              ref={register({
                pattern: {
                  value: REGEXP.NAME,
                  message: 'Invalid name'
                }
              })}
              name="Buyer2_name"
              defaultValue=""
              error={errors.Buyer2_name}
              label="Co-buyer Full Name"
              placeholder="Enter Name"
              data-cy="cobuyer_name"
            />
            <Input
              ref={register({
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              name="Buyer2_email"
              defaultValue=""
              error={errors.Buyer2_email}
              className="info-form__input"
              label="Co-buyer Email Address"
              placeholder="Enter Email"
              data-cy="cobuyer_email"
            />
          </div>
        )}

        <hr />

        <div className="info-form__btn-group">
          <Button
            className="info-form__btn-add"
            simple
            onClick={e => {
              e.preventDefault();
              setShowCompanyField(v => !v);
            }}
            data-cy="add_company">
            {showCompanyField ? '-' : '+'} Buying as a Company
          </Button>
        </div>

        {showCompanyField && (
          <div className="info-form__input-group">
            <Input
              className="info-form__input"
              ref={register}
              name="company_name"
              defaultValue=""
              error={errors.company_name}
              label="Company Name"
              placeholder="Enter Name"
              data-cy="company_name"
            />
            <Input
              ref={register({
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              name="company_email"
              defaultValue=""
              error={errors.company_email}
              className="info-form__input"
              label="Company Email Address"
              placeholder="Enter Email"
              data-cy="company_email"
            />
          </div>
        )}
        <div className="info-form__submit-group">
          <Button className="info-form__btn-submit" primary data-cy="continue-button">
            Continue
          </Button>
        </div>
      </form>
    </InfoFormContainer>
  );
};

export default BuyerInfoForm;
