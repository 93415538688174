import React from 'react';
import IndexLayout from 'layouts';
import BuyProcess from 'views/BuyProcessPage';
import { Components } from 'sitedata';
import { NoSSR } from 'components';
import Helmet from 'react-helmet';

const BuyProcessPage = () => (
  <IndexLayout>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Components.Header />
    <NoSSR>
      <BuyProcess />
    </NoSSR>
    <Components.Footer />
  </IndexLayout>
);

export default BuyProcessPage;
