import React, { useContext } from 'react';

import { AuthContext } from 'auth';
import { Button, Icon } from 'components';
import Container from 'components/Container';
import BuyerInfoForm from './BuyerInfoForm';
import BuyProcessContext from '../context';

interface InfoProps {
  open?: boolean;
}

const Info: React.FC<InfoProps> = () => {
  const { profile } = useContext(AuthContext);
  const {
    listingId,
    activeTab,
    infoTabData,
    setInfoTabData,
    setActiveTab
  } = useContext(BuyProcessContext);
  const formDefaultValues = {
    name: infoTabData?.Buyer_name || [profile?.firstName, profile?.lastName].join(' '),
    email: infoTabData?.Buyer_email || profile?.email,
    phone: infoTabData?.Buyer_phone || infoTabData?.agent_phone || profile?.phone,
    ...infoTabData
  };
  const open = activeTab === 'info';

  const handleSubmit = value => {
    setInfoTabData(value);
    setActiveTab('finance');
  };

  return (
    <>
      {open && (
        <Container info data-cy="info-block">
          <Icon name="real-estate1" /> Start your offer by filling out the details below.
        </Container>
      )}
      <Container number={1} header active={open} merged={open ? 'top' : undefined}>
        Your Information{' '}
        {infoTabData && !open && (
          <Button link onClick={() => setActiveTab('info')} className="edit-btn">
            Edit
          </Button>
        )}
      </Container>
      {open && (
        <Container merged="bottom">
          <BuyerInfoForm
            listingId={listingId}
            defaultValues={formDefaultValues}
            onSubmit={values => handleSubmit({ ...values, type: 'buyer' })}
          />
        </Container>
      )}
    </>
  );
};

export default Info;
