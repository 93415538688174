import React from 'react';

import { formatCurrency } from 'utils/rifmFormatters';

const inspectionContigencyTexts = {
  asIs: 'As is',
  yes: 'Yes',
  no: 'No'
};

interface DeadlineSummaryProps {
  data: { [key: string]: any; };
  onEdit: (value: BuyProcessTabKey) => void;
}

const DeadlineSummary: React.FC<DeadlineSummaryProps> = ({ data, onEdit }) => {
  return (
    <div className="review-wrapper">
      <button
        className="review-block__edit-btn"
        onClick={() => onEdit('deadline')}
        data-cy="review_deadline_edit">
        Edit
      </button>
      <div className="review-block">
        <div className="review-info-block">
          <h3 className="review-title">Deadline:</h3>
          <p className="review-text" data-cy="review_closing_date">
            <strong>Closing Date: </strong>
            {data?.deadlineDate || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_attorney_name">
            <strong>Attorney: </strong>
            {data?.BuyerAttorney_name || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_attorney_phone">
            <strong>Attorney Phone: </strong>
            {data?.BuyerAttorney_phone || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_attorney_email">
            <strong>Attorney Email: </strong>
            {data?.BuyerAttorney_email || 'N/A'}
          </p>
        </div>
        <div className="review-info-block">
          <div className="review-title empty" />
          <p className="review-text" data-cy="review_lender_name">
            <strong>Lender: </strong>
            {data?.Lender_name || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_lender_phone">
            <strong>Lender Phone: </strong>
            {data?.Lender_phone || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_lender_email">
            <strong>Lender Email: </strong>
            {data?.Lender_email || 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeadlineSummary;
