import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { BUSINESS_HOURS_START, BUSINESS_HOURS_END } from 'consts';

dayjs.extend(isSameOrBefore);

export const getBusinessHours = (startDate = dayjs()) => {
  let startOfDay = dayjs(startDate)
    .hour(BUSINESS_HOURS_START)
    .minute(0)
    .second(0);
  const endOfDay = dayjs(startDate)
    .hour(BUSINESS_HOURS_END)
    .minute(0)
    .second(0);
  const startOptions: { value: string; text: string }[] = [];
  const endOptions: { value: string; text: string }[] = [];

  while (startOfDay.isSameOrBefore(endOfDay.subtract(30, 'minute'))) {
    const currentTime = {
      value: startOfDay.format('hh:mm A'),
      text: startOfDay.format('hh:mm A')
    };
    const defaultEndTime = startOfDay.add(30, 'minute');
    startOptions.push(currentTime);
    endOptions.push({
      value: defaultEndTime.format('hh:mm A'),
      text: defaultEndTime.format('hh:mm A')
    });
    startOfDay = startOfDay.add(30, 'minute');
  }

  return {
    start: startOptions,
    end: endOptions
  };
};

export const disablePastDates = input => {
  const current = dayjs().startOf('day');
  return current.isSameOrBefore(input);
};

export const disableFutureDates = input => {
  const current = dayjs();
  return current.isAfter(input);
};
