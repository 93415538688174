import React from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import { disablePastDates } from 'utils/date';
import { REGEXP } from 'consts';
import styled from '@emotion/styled';
import { Button, RadioButton, Input, DateInput } from 'components';

interface DeadlineFormProps {
  onSubmit: (values: { [key: string]: any; }) => void;
  defaultValues?: { [key: string]: any; };
}

const getDeadline = (numberOfDays: number) => {
  return dayjs()
    .add(numberOfDays, 'day')
    .format('MM/DD/YYYY');
};

const DeadlineForm: React.FC<DeadlineFormProps> = ({ onSubmit, defaultValues }) => {
  const { register, control, errors, watch, handleSubmit } = useForm({
    defaultValues: {
      ...defaultValues,
    }
  });
  const deadlineDate = watch('deadlineDate');
  const showCustomDeadlineDate = deadlineDate === 'Custom' || Boolean(defaultValues?.customDeadlineDate);

  const submit = values => {
    onSubmit({
      ...values,
      deadlineDate: values.customDeadlineDate || values.deadlineDate
    });
  };

  return (
    <StyledForm className="deadline-form" onSubmit={handleSubmit(submit)}>
      <p>When do you want to close?</p>
      <div className="deadline-form__radio-group">
        <RadioButton
          ref={register}
          value={getDeadline(30)}
          name="deadlineDate"
          label="30 days"
          buttonLike
          data-cy="closing_30_days"
        />
        <RadioButton
          ref={register}
          value={getDeadline(60)}
          name="deadlineDate"
          label="60 days"
          buttonLike
          defaultChecked={!Boolean(defaultValues?.customDeadlineDate)}
          data-cy="closing_60_days"
        />
        <RadioButton
          ref={register}
          value={getDeadline(90)}
          name="deadlineDate"
          label="90 days"
          buttonLike
          data-cy="closing_90_days"
        />
        <RadioButton
          ref={register}
          value="N/A"
          name="deadlineDate"
          label="I don't know"
          buttonLike
          data-cy="closing_na_days"
        />
        <RadioButton
          ref={register}
          value="Custom"
          name="deadlineDate"
          label="Custom"
          defaultChecked={Boolean(defaultValues?.customDeadlineDate)}
          buttonLike
          data-cy="closing_custom_days"
        />
      </div>
      <p className="deadline-form__description">
        For this home we recommend a 60-day close. Our expert will work with you to suggest a
        timeline that meets your needs and makes your offer both competitive and relevant
      </p>
      {showCustomDeadlineDate && (
        <>
          <div className="offset-top" />

          <div className="deadline-form__input-group">
            <Controller
              control={control}
              rules={{
                required: 'Date required'
              }}
              defaultValue=''
              name="customDeadlineDate"
              render={({ onChange, ...controllerProps }) => (
                <DateInput
                  inputProps={{
                    label: 'Select Date*',
                    placeholder: "MM/DD/YYYY",
                    error: errors.customDeadlineDate
                  }}
                  isValidDate={disablePastDates}
                  onChange={value => onChange(dayjs(value).format("MM/DD/YYYY"))}
                  className="deadline-form__input"
                  {...controllerProps}
                />
              )}
            />
          </div>
        </>
      )}
      <hr />
      <div className="offset-top" />

      <div className="offset-top" />

      <div className="deadline-form__input-group">
        <Input
          className="deadline-form__input"
          ref={register({
            pattern: {
              value: REGEXP.NAME,
              message: 'Invalid name'
            }
          })}
          name="BuyerAttorney_name"
          error={errors.BuyerAttorney_name}
          label="Attorney Name"
          placeholder="Enter Attorney Name"
          data-cy="attorneyName"
        />
        <Input
          ref={register({
            pattern: {
              value: REGEXP.EMAIL,
              message: 'Invalid email address'
            }
          })}
          name="BuyerAttorney_email"
          error={errors.BuyerAttorney_email}
          className="deadline-form__input"
          label="Attorney Email"
          placeholder="Enter Attorney Email"
          data-cy="attorneyEmail"
        />
      </div>

      <div className="deadline-form__input-group">
        <Controller
          control={control}
          name="BuyerAttorney_phone"
          data-cy="attorneyPhone"
          rules={{
            validate: value => !value.includes('_')
          }}
          defaultValue=""
          render={controllerProps => (
            <Input
              className="deadline-form__input"
              as={MaskedInput}
              mask="+1 (999) 999-9999"
              type="tel"
              error={errors.BuyerAttorney_phone}
              label="Attorney Phone"
              placeholder="Enter Attorney Phone"
              {...controllerProps}
            />
          )}
        />
      </div>
      <div className="deadline-form__input-group">
        <Input
          className="deadline-form__input"
          ref={register}
          name="Lender_name"
          error={errors.Lender_name}
          label="Lender Name"
          placeholder="Enter Lender Name"
          data-cy="lenderName"
        />
        <Input
          ref={register({
            pattern: {
              value: REGEXP.EMAIL,
              message: 'Invalid email address'
            }
          })}
          name="Lender_email"
          error={errors.Lender_email}
          className="deadline-form__input"
          label="Lender Email"
          placeholder="Enter Lender Email"
          data-cy="lenderEmail"
        />
      </div>

      <div className="deadline-form__input-group">
        <Controller
          control={control}
          name="Lender_phone"
          data-cy="lenderPhone"
          rules={{
            validate: value => !value.includes('_')
          }}
          defaultValue=""
          render={controllerProps => (
            <Input
              className="deadline-form__input"
              as={MaskedInput}
              mask="+1 (999) 999-9999"
              type="tel"
              error={errors.Lender_phone}
              label="Lender Phone"
              placeholder="Enter Lender Phone"
              {...controllerProps}
            />
          )}
        />
      </div>
      <Button className="deadline-form__submit-btn" primary data-cy="continue-button">
        Continue
      </Button>
    </StyledForm>
  );
};

export default DeadlineForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0 0 12px 0;
  }

  .input,
  .dropdown {
    &__component {
      height: 40px;
    }
    label {
      font-size: 10px;
      line-height: 16px;
    }
  }

  .deadline-form__input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
  }
  .deadline-form__input {
    width: 100%;
    .dropdown__button {
      height: 40px;
    }
  }

  .deadline-form__radio-group {
    & > .label {
      margin-bottom: 8px;
    }
    .radiobutton {
      margin-right: 20px;
      &.button-like .label {
        white-space: nowrap;
      }
    }
  }
  .deadline-form__description {
    color: ${props => props.theme.colors.etherealGrey};
    margin: 6px 0 0;
    font-size: 12px;
    line-height: 16px;
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .deadline-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .deadline-form__input-group {
      grid-template-columns: 1fr;
      grid-gap: 12px 0;
    }
    .deadline-form__submit-btn {
      width: 100%;
    }
  }
`;
