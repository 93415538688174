import { Button, Container } from 'components';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import BuyProcessContext from '../../context';
import { ReactComponent as AcceptSVG } from './accept.svg';

const LinkButton = Button as any;

const ConfirmationScreen = () => {
  const { infoTabData } = useContext(BuyProcessContext);
  return (
    <StyledConfirmationScreen>
      <Container className="success" header>
        <span className="icon icon-circle">
          <AcceptSVG />
        </span>
        Thank you! Your offer is placed. We will send you an email once your offer get reviewed
      </Container>
      <Container className="confirmation">
        <h3 className="confirmation__title">
          Confirmation of your offer has been sent to {infoTabData?.Buyer_email}
        </h3>
        <p>Please check your email for confirmation. To go to home page click Home</p>
        <div className="button-container">
          <LinkButton as={Link} to="/" primary>
            Home
          </LinkButton>
        </div>
      </Container>
    </StyledConfirmationScreen>
  );
};

export default ConfirmationScreen;

const StyledConfirmationScreen = styled.div`
  .icon-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 50%;
    fill: ${props => props.theme.colors.primary};
  }

  .success {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;

    .icon {
      flex-shrink: 0;
    }
  }

  .confirmation {
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 12px 0;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }

    .button {
      display: inline-flex;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      width: 164px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .success {
      font-size: 14px;
      line-height: 22px;
    }

    .confirmation {
      p {
        margin: 18px 0 32px 0;
      }
      .button {
        width: 100%;
      }
    }
  }
`;
