import { urlEncodeBody } from 'utils';
import { fetchWithToken } from './api';

export async function uploadFileToS3(
  file: File,
  config?: { publicAccess?: boolean; listingId?: any; doctype: string }
): Promise<{
  url: string;
  documentId: number;
}> {
  // presign document
  const { presigned_url: presignedUrl, document_id: documentId } = await (
    await fetchWithToken('/documents', {
      method: 'POST',
      body: urlEncodeBody({
        filename: file.name,
        size: file.size,
        public_access: config?.publicAccess ? 1 : 0,
        listing_id: config?.listingId,
        doctype: config?.doctype
      })
    })
  ).json();
  // upload file to s3
  await fetch(presignedUrl, { method: 'PUT', body: file });
  // confirm upload success
  const url = await (await fetchWithToken(`/documents/${documentId}`, { method: 'PUT' })).json();

  return { url, documentId };
}
